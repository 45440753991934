import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import "./index.sass";

const Copyright = () => (
  <Fragment>
    <FontAwesomeIcon icon={faCopyright} size="sm" />
    <span className="is-uppercase is-size-7 copyright">
      &nbsp;2017-2024 TJ Restaurant. All rights Reserved.
    </span>
  </Fragment>
);

export default Copyright;
