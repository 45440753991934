import React, { Fragment, forwardRef } from 'react';
import ContactBox from '../common/ContactBox/ContactBox';
import carbonBg from '../../img/real-carbon-fibre.png';
import ReactMarkdown from 'react-markdown';

const Contact = forwardRef(({ contacts }, ref) => {
  const renderContactHtml = (title, description) => {
    let result = '';

    switch (title) {
      case 'Address':
        result = (
          <ReactMarkdown
            className='center-col contact-address'
            children={description}
          />
        );
        break;
      case 'Opening time':
        result = description
          .split('\n')
          .map((desc, index) => (
            <ReactMarkdown
              key={index}
              className='center-col contact-opening'
              children={desc}
            />
          ));
        break;
      case 'Contact':
        result = (
          <ReactMarkdown
            className='center-col contact-phone'
            children={description}
          />
        );
        break;
      default:
    }

    return result;
  };

  return (
    <Fragment>
      <div
        data-overlay-dark='0'
        className='has-background-black text-extra-light-gray'
        style={{ backgroundImage: `url(${carbonBg})` }}
        ref={ref}
      >
        <div className='container is-max-widescreen'>
          <div className='columns py-6'>
            {contacts.map(({ title, description }, index) => (
              <div className='column' key={index}>
                <ContactBox title={title} key={index}>
                  {renderContactHtml(title, description)}
                </ContactBox>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default Contact;
