import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getSrc } from 'gatsby-plugin-image';

import 'swiper/swiper.scss';
import './index.sass';

SwiperCore.use([Pagination, Autoplay]);

const Annoucement = forwardRef(({ annoucements = [] }, ref) => {
  const size = annoucements.length;
  return (
    <div ref={ref} className='container is-fluid'>
      <Swiper
        autoplay={ size > 1 ? { delay: 10000 } : false }
        pagination={{ clickable: true }}
        loop
        speed={1000}
      >
        {annoucements.map(
          (annoucement, index) =>
            Object.keys(annoucement).length !== 0 && (
              <SwiperSlide
                className='caption'
                key={index}
                data-overlay-dark='8'
                style={{
                  backgroundImage: `${
                    annoucement.hasOwnProperty('background')
                      ? `url(${
                          typeof annoucement.background == 'string'
                            ? annoucement.background
                            : getSrc(annoucement.background)
                        })`
                      : ''
                  }`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no - repeat',
                }}
              >
                {({ isActive }) => (
                  <div
                    className={`container ${
                      isActive ? 'animated fadeInUp' : ''
                    } annoucement`}
                  >
                    <div className='columns is-centered has-text-centered my-3'>
                      <div className='column is-half'>
                        <h2 className='title is-size-4-mobile'>
                          {annoucement.title}
                        </h2>
                      </div>
                    </div>
                    <div className='columns is-centered has-text-centered'>
                      <div className='column is-three-fifths'>
                        {annoucement.description &&
                          annoucement.description
                            .split('\n')
                            .map((desc, index) => (
                              <p
                                key={index}
                                className='subtitle is-6 has-text-white'
                              >
                                {desc}
                              </p>
                            ))}
                      </div>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            )
        )}
      </Swiper>
    </div>
  );
});

Annoucement.propTypes = {
  annoucements: PropTypes.array,
  getPassRef: PropTypes.func,
};

export default Annoucement;
