import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import './index.sass';

const AboutUs = forwardRef(({ aboutus }, ref) => {
  return (
    <section
      data-overlay-dark='7'
      style={{
        backgroundImage: `url(${
          typeof aboutus.background == 'string'
            ? aboutus.background
            : getSrc(aboutus.background)
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      }}
      ref={ref}
    >
      <div className='container is-max-widescreen'>
        <div className='columns is-vcentered'>
          <div className='column is-4'>
            {typeof aboutus.image !== 'string' ? (
              <GatsbyImage
                className='image'
                image={getImage(aboutus.image)}
                alt='TJ story'
              />
            ) : (
              <img src={aboutus.image} alt='TJ story' />
            )}
          </div>
          <div className='column is-offset-1 is-offset-0-mobile'>
            <div className='section-heading mb-6'>
              <div className='title'>{aboutus.title}</div>
              <h4 className='has-text-white'>{aboutus.subtitle}</h4>
            </div>
            {aboutus.description.split('\n').map((desc, index) => (
              <p key={index} className='has-text-white mb-5'>
                {desc}
              </p>
            ))}
            <div className='separator-line-verticle-small-thick bg-theme center'></div>
          </div>
        </div>
      </div>
    </section>
  );
});

AboutUs.propTypes = {
  aboutus: PropTypes.object,
};

export default AboutUs;
