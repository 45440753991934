import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuFilter = (props) => {
  let content = '';
  if (props.icon) {
    content = <FontAwesomeIcon icon={props.icon} />;
  }
  if (props.svg) {
    content = <img src={props.svg} alt={`${props.name} icon`} />;
  }
  return (
    <li className={`tab ${props.activeTab === props.name ? 'is-active' : ''}`}>
      <a
        onClick={() => props.onActiveTab(props.name)}
        aria-hidden='true'
        role='button'
      >
        <span className={`icon ${props.className}`}>{content}</span>
        <span>{props.title}</span>
      </a>
    </li>
  );
};

MenuFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  svg: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  onActiveTab: PropTypes.func.isRequired,
};

export default MenuFilter;
