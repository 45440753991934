import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LinkIcon from '../common/LinkIcon';
import NavBarItem from './NavBarItem';

const NavBar = (props) => {
  const [isHambergerActive, setIsHambergerActive] = useState(false);

  const hambergerMenuHandler = (event) => {
    event.preventDefault();
    setIsHambergerActive(!isHambergerActive);
  };

  return (
    <nav
      className={`navbar alt-font ${
        props.windowYOffset > 100 ? 'nav-scroll' : ''
      }`}
      role='navigation'
      aria-label='main navigation'
    >
      <div className='container is-max-widescreen'>
        <div className='navbar-brand'>
          <a className='navbar-item' href='/'>
            {typeof props.logo !== 'string' ? (
              <GatsbyImage
                image={getImage(props.logo)}
                alt='TJ Restaurant'
                title='TJ Restaurant'
              />
            ) : (
              <img src={props.logo} alt='TJ Restaurant' title='TJ Restaurant' />
            )}
          </a>
          <a
            href='/#'
            role='button'
            className={`navbar-burger burger ${
              isHambergerActive ? 'is-active' : ''
            }`}
            aria-label='menu'
            aria-expanded='false'
            data-target='navMenu'
            onClick={hambergerMenuHandler}
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>
        <div
          id='navMenu'
          className={`navbar-menu ${isHambergerActive ? 'is-active' : ''}`}
        >
          <div className='navbar-end'>
            <NavBarItem
              name='Home'
              activeNav={props.activeNav}
              onScrollToNav={props.onScrollTo}
            >
              Home
            </NavBarItem>
            <NavBarItem
              name='Menu'
              activeNav={props.activeNav}
              onScrollToNav={props.onScrollTo}
            >
              Menu
            </NavBarItem>
            <NavBarItem
              name='Gallery'
              activeNav={props.activeNav}
              onScrollToNav={props.onScrollTo}
            >
              Gallery
            </NavBarItem>
            <NavBarItem
              name='About'
              activeNav={props.activeNav}
              onScrollToNav={props.onScrollTo}
            >
              About
            </NavBarItem>
            <NavBarItem
              name='Contact'
              activeNav={props.activeNav}
              onScrollToNav={props.onScrollTo}
            >
              Contact
            </NavBarItem>
          </div>
          <div className='navbar-end'>
            <LinkIcon
              className='navbar-item'
              link={props.facebookLink}
              icon={faFacebookF}
            />
            <LinkIcon
              className='navbar-item'
              link={props.instagramLink}
              icon={faInstagram}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  logo: PropTypes.object,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
};

export default NavBar;
