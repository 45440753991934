import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

const GalleryItem = (props) => {
  return (
    <div className='column is-one-quarter'>
      <figure className='image'>
        {typeof props.image === 'string' ? (
          <div className='item-img'>
            <img src={props.image} alt='gallery' />
          </div>
        ) : (
          <div className='item-img'>
            <GatsbyImage image={getImage(props.image)} alt='gallery' />
            <div className='item-img-overlay center'>
              <div className='overlay-info'>
                <span
                  className='icon has-text-centered'
                  onClick={() => props.onOpenViewer(props.id)}
                  aria-hidden='true'
                  role='button'
                >
                  <FontAwesomeIcon icon={faSearchPlus} />
                </span>
              </div>
            </div>
          </div>
        )}
      </figure>
    </div>
  );
};

GalleryItem.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.object.isRequired,
  onOpenViewer: PropTypes.func.isRequired,
};

export default GalleryItem;
