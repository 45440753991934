import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { getSrc } from 'gatsby-plugin-image';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import './index.sass';
import GalleryItem from './GalleryItem';

const Gallery = forwardRef(({ gallery }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const images =
    gallery &&
    gallery.images.map(
      ({ image }) =>
        image && (typeof image === 'string' ? image : getSrc(image))
    );

  const imageViewerOpenHandler = (imageIndex) => {
    setIsOpen(true);
    setImageIndex(imageIndex);
    const navScroll = getNav();
    navScroll.style.display = 'none';
  };

  const imageViewerCloseHandler = () => {
    setIsOpen(false);
    const navScroll = getNav();
    navScroll.style.display = '';
  };

  const getNav = () => {
    return document.getElementsByClassName('navbar')[0];
  };

  return (
    <section
      data-overlay-dark='7'
      style={{
        backgroundImage: `url(${
          typeof gallery.background === 'string'
            ? gallery.background
            : getSrc(gallery.background)
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      }}
      ref={ref}
    >
      <div className='container is-max-widescreen'>
        <div className='columns is-centered'>
          <div className='column section-heading has-text-centered'>
            <div className='title has-text-centered'>{gallery.title}</div>
            <h4 className='has-text-white'>{gallery.subtitle}</h4>
          </div>
        </div>
        <div className='columns is-multiline is-variable is-2 mt-6'>
          {gallery.images.map(
            ({ image }, index) =>
              image && (
                <GalleryItem
                  key={index}
                  id={index}
                  image={image}
                  onOpenViewer={imageViewerOpenHandler}
                />
              )
          )}
          {isOpen && (
            <Lightbox
              images={images}
              startIndex={imageIndex}
              onClose={imageViewerCloseHandler}
              doubleClickZoom={0}
            />
          )}
        </div>
      </div>
    </section>
  );
});

Gallery.propTypes = {
  gallery: PropTypes.object,
};

export default Gallery;
