import React, { useEffect, useState } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const BackToTop = () => {
  const [active, setActive] = useState(false);

  const showBackToTopHandler = () => {
    if (window.pageYOffset > 500) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const slideToTopHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', showBackToTopHandler);
  }, [active]);

  return (
    <div
      className={`back-to-top ${active ? 'active' : ''}`}
      onClick={slideToTopHandler}
      aria-hidden='true'
      role='button'
    >
      <FontAwesomeIcon icon={faAngleUp} />
    </div>
  );
};

export default BackToTop;
