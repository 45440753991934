import React, { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import NavBar from '../components/NavBar/NavBar';
import Annoucement from '../components/Annoucement/Annoucement';
import AboutUs from '../components/AboutUs/AboutUs';
// import SpecialOffer from '../components/SpecialOffer/SpecialOffer';
import Menu from '../components/Menu/Menu';
import Gallery from '../components/Gallery/Gallery';
import Map from '../components/Map/Map';
import Footer from '../components/Footer/Footer';

export const IndexPageTemplate = ({
  logo,
  facebookLink,
  instagramLink,
  annoucements,
  aboutus,
  // offer,
  menu,
  gallery,
  contacts,
  homeRef,
  aboutRef,
  menuRef,
  galleryRef,
  contactRef,
  activeNav,
  onScrollTo,
  windowYOffset,
}) => (
  <Fragment>
    <NavBar
      logo={logo}
      facebookLink={facebookLink}
      instagramLink={instagramLink}
      activeNav={activeNav}
      onScrollTo={onScrollTo}
      windowYOffset={windowYOffset}
    />
    <Annoucement annoucements={annoucements} ref={homeRef} />
    <Map />
    {/* <SpecialOffer offer={offer} /> */}
    <Menu menu={menu} ref={menuRef} />
    <Gallery gallery={gallery} ref={galleryRef} />
    <AboutUs aboutus={aboutus} ref={aboutRef} />
    <Footer
      contacts={contacts}
      facebookLink={facebookLink}
      instagramLink={instagramLink}
      ref={contactRef}
    />
  </Fragment>
);

IndexPageTemplate.propTypes = {
  logo: PropTypes.object,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
  annoucements: PropTypes.array,
  aboutus: PropTypes.object,
  // offer: PropTypes.object,
  menu: PropTypes.object,
  gallery: PropTypes.object,
  contacts: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const menuRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const [activeNav, setActiveNav] = useState('Home');
  const [windowYOffset, setWindowYOffset] = useState(0);

  const scrollToHandler = (event, refStr) => {
    event.preventDefault();
    let ref = '';

    switch (refStr) {
      case 'Home':
        ref = homeRef;
        break;
      case 'About':
        ref = aboutRef;
        break;
      case 'Menu':
        ref = menuRef;
        break;
      case 'Gallery':
        ref = galleryRef;
        break;
      case 'Contact':
        ref = contactRef;
        break;
      default:
        ref = homeRef;
        break;
    }
    setActiveNav(refStr);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const listenToScrollHandler = () => {
    if (
      contactRef.current ||
      galleryRef.current ||
      menuRef.current ||
      aboutRef.current
    ) {
      if (window.pageYOffset >= contactRef.current.offsetTop - 700) {
        setActiveNav('Contact');
      } else if (window.pageYOffset >= galleryRef.current.offsetTop - 100) {
        setActiveNav('Gallery');
      } else if (window.pageYOffset >= menuRef.current.offsetTop - 100) {
        setActiveNav('Menu');
      } else if (window.pageYOffset >= aboutRef.current.offsetTop - 100) {
        setActiveNav('About');
      } else {
        setActiveNav('Home');
      }
    }

    setWindowYOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScrollHandler);
  }, []);

  return (
    <Layout>
      <IndexPageTemplate
        logo={frontmatter.logo}
        facebookLink={frontmatter.facebookLink}
        instagramLink={frontmatter.instagramLink}
        annoucements={frontmatter.annoucements}
        aboutus={frontmatter.aboutus}
        // offer={frontmatter.offer}
        menu={frontmatter.menu}
        gallery={frontmatter.gallery}
        contacts={frontmatter.contacts}
        homeRef={homeRef}
        aboutRef={aboutRef}
        menuRef={menuRef}
        galleryRef={galleryRef}
        contactRef={contactRef}
        activeNav={activeNav}
        onScrollTo={scrollToHandler}
        windowYOffset={windowYOffset}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        logo {
          childImageSharp {
            gatsbyImageData(width: 120)
          }
        }
        facebookLink
        instagramLink
        annoucements {
          title
          description
          background {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
        menu {
          title
          subtitle
          tabs {
            name
            images {
              image {
                childImageSharp {
                  gatsbyImageData(width: 1600, quality: 100)
                }
              }
            }
          }
          background {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
        gallery {
          title
          subtitle
          images {
            image {
              childImageSharp {
                gatsbyImageData(width: 1600, quality: 100)
              }
            }
          }
          background {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
        aboutus {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 1600, quality: 100)
            }
          }
          background {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
        contacts {
          title
          description
        }
      }
    }
  }
`;
