import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import sushiIcon from '../../img/sushi.svg';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import './index.sass';
import MenuFilter from './MenuFilter';

SwiperCore.use([Pagination, Autoplay]);

const Menu = forwardRef(({ menu }, ref) => {
  const [activeTab, setActiveTab] = useState('main');

  return (
    <section
      data-overlay-dark='7'
      style={{
        backgroundImage: `url(${
          typeof menu.background == 'string'
            ? menu.background
            : getSrc(menu.background)
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      }}
      ref={ref}
    >
      <div className='container'>
        <div className='columns is-centered'>
          <div className='column section-heading has-text-centered'>
            <div className='title has-text-centered'>{menu.title}</div>
            <h4 className='has-text-white'>{menu.subtitle}</h4>
          </div>
        </div>
        <div className='columns is-centered mt-5'>
          <div className='tabs is-centered is-toggle is-toggle-rounded'>
            <ul>
              <MenuFilter
                name='main'
                title='Main Menu'
                icon={faUtensils}
                activeTab={activeTab}
                onActiveTab={setActiveTab}
              />
              <MenuFilter
                className='sushi'
                name='sushi'
                title='Sushi'
                svg={sushiIcon}
                activeTab={activeTab}
                onActiveTab={setActiveTab}
              />
            </ul>
          </div>
        </div>
        <div>
          <div className='tab-content'>
            <div className='columns is-centered mt-6'>
              <div className='column is-three-fifths'>
                <Swiper
                  autoplay={ false } 
                  pagination={{ clickable: true }}
                  loop
                  speed={1000}
                >
                  {menu.tabs.map(
                    ({ name, images }) =>
                      name === activeTab &&
                      images.map(
                        ({ image }, index) =>
                          image && (
                            <SwiperSlide key={index}>
                              {typeof image === 'string' ? (
                                <img src={image} alt='menu' />
                              ) : (
                                <GatsbyImage
                                  image={getImage(image)}
                                  alt='menu'
                                />
                              )}
                            </SwiperSlide>
                          )
                      )
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

Menu.propTypes = {
  menu: PropTypes.object,
};

export default Menu;
