import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './index.sass';

const ContactBox = ({ title, children }) => {
  const separatorPercent = title === 'Opening time' ? 25 : 15;
  return (
    <div className='has-text-centered'>
      <h4 className='contact-title has-text-white'>{title}</h4>
      <div
        className={`separator-line-${separatorPercent} bg-red center-col mt-3 mb-4`}
      />
      {title === 'Contact' && (
        <FontAwesomeIcon
          className='contact-phone__icon'
          icon={faPhone}
          size='lg'
        />
      )}
      {children}
    </div>
  );
};

ContactBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default ContactBox;
