import React from 'react';

const Map = () => {
  return (
    <iframe
      title='TJ Restaurant map'
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.9556820412563!2d-1.7841665845709136!3d53.647762380043304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bdc727f563745%3A0xeaaa3bd9f03dba89!2sTJ+Thai+%26+Japanese+Restaurant!5e0!3m2!1sen!2sth!4v1555176407384!5m2!1sen!2sth'
      width='100%'
      height='500'
      frameBorder='0'
      allowFullScreen
    ></iframe>
  );
};

export default Map;
