import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinkIcon = (props) => {
  return (
    <a className={props.className} href={props.link}>
      <span className='icon'>
        <FontAwesomeIcon icon={props.icon} />
      </span>
    </a>
  );
};

LinkIcon.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default LinkIcon;
