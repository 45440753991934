import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Contact from '../Contact/Contact';
import Copyright from '../Copyright/Copyright';
import './index.sass';
import LinkIcon from '../common/LinkIcon';
import BackToTop from '../common/BackToTop/BackToTop';

const Footer = forwardRef(({ contacts, facebookLink, instagramLink }, ref) => (
  <footer>
    <Contact contacts={contacts} ref={ref} />
    <div className='has-background-black border-color-light-white border-top mt-3'>
      <div className='container is-max-widescreen'>
        <div className='columns m-0'>
          <div className='column text-extra-light-gray copyright'>
            <Copyright />
          </div>
          <div className='column footer-social'>
            <div className='has-text-right'>
              <LinkIcon link={facebookLink} icon={faFacebookF} />
              <LinkIcon link={instagramLink} icon={faInstagram} />
            </div>
          </div>
          <BackToTop />
        </div>
      </div>
    </div>
  </footer>
));
Footer.propTypes = {
  contacts: PropTypes.array,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
};

export default Footer;
