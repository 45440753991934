import React from 'react';
import PropTypes from 'prop-types';

const NavBarItem = (props) => {
  return (
    <a
      href='/#'
      className={`navbar-item ${
        props.activeNav === props.name ? 'is-active' : ''
      }`}
      onClick={(event) => props.onScrollToNav(event, props.name)}
    >
      {props.children}
    </a>
  );
};

NavBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  activeNav: PropTypes.string.isRequired,
  onScrollToNav: PropTypes.func.isRequired,
};

export default NavBarItem;
